import * as React from "react";
import Page from "../components/Page";
import {
  Copyright, Footer,
  Nav, Partners,
  SectionAssigment,
} from "../components/partials";
import {assigmentData} from "../pagesData/dealers";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";

const LeasingCompaniesPage = () => {
    const {t} = useTranslation();

    return (
        <Page>
            <SEO title={t('page_title_custom')}/>
            <Nav/>
            <SectionAssigment {...assigmentData} />
            <Footer/>
            <Partners/>
            <Copyright/>
        </Page>
    );
};

export default LeasingCompaniesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;